<template>
  <div>
    <div class="text-primary text-bold text-md  mb-3">
      Essential Details of the Deceased
    </div>
    <s-row class="common-row">
      <s-column :size="3" class="mb-4">
        <div class="text-sm text-grey mb-1">
          First Name
        </div>
        <div v-if="funeralDetails.first_name">
          {{ funeralDetails.first_name }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3" class="mb-4">
        <div class="text-sm text-grey mb-1">
          Surname or Last Name
        </div>
        <div v-if="funeralDetails.last_name">
          {{ funeralDetails.last_name }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3" class="mb-4">
        <div class="text-sm text-grey mb-1">
          Maiden Name
        </div>
        <div v-if="funeralDetails.maiden_name">
          {{ funeralDetails.maiden_name }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
    <s-row class="common-row">
      <s-column :size="3" class="mb-4">
        <div class="text-sm text-grey mb-1">
          Gender
        </div>
        <div v-if="funeralDetails.gender">
          {{ funeralDetails.gender }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Current Location
        </div>
        <div v-if="funeralDetails.place_of_death">
          {{ funeralDetails.place_of_death }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Marital Status
        </div>
        <div v-if="funeralDetails.marital_status">
          {{ funeralDetails.marital_status }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
    <s-row class="mb-4 common-row">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Religion
        </div>
        <div v-if="funeralDetails.religion">
          {{ funeralDetails.religion }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Town
        </div>
        <div v-if="funeralDetails.town">
          {{ funeralDetails.town }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          County
        </div>
        <div v-if="funeralDetails.country">
          {{ funeralDetails.country }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
    <s-row class="pb-4 common-row mb-3">
      <s-column :size="4">
        <div class="text-sm text-grey mb-1">
          Post Code
        </div>
        <div v-if="funeralDetails.post_code">
          {{ funeralDetails.post_code }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
  </div>
</template>
<script>
export default {
  props: {
    funeralDetails: [Object],
  },
};
</script>
