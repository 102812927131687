<template>
  <div :class="{ 'disable-card': disabled }">
    <div
      v-for="(option, index) in options"
      class="card p-2 flex flex--nowrap flex--middle mb-3"
      :class="{
        'card-active': multiple
          ? isActive(option)
          : value.some((p) => p.id === option.id),
        'cursor-pointer': option.product_type !== 'variation',
      }"
      @click="option.product_type === 'variation' ? '' : select(option, $event)"
      :key="index"
      :title="option.product_type === 'variation' ? '' : 'Click to add'"
    >
      <div
        class="flex__fit mr-3 lightbox-container"
        :title="option.image ? 'Click to open' : ''"
      >
        <!-- <float-preview
          v-if="option.image"
          class="img-size"
          :src="option.image"
          :max-width="500"
          :max-height="400"
        /> -->
        <silent-box
          v-if="option.image"
          ref="silentbox"
          :gallery="[
            {
              src: option.image,
              srcSet: option.image,
              thumbnailWidth: '90px',
              thumbnailHeight: '65px',
            },
          ]"
        />
        <s-media
          v-else
          :value="option.image"
          ratio="16x9"
          class="radius-3 bg-grey-lightest"
          width="90px"
          height="65px"
        />
        <s-button
          v-if="option.image"
          size="xs"
          name="expand-image"
          theme="muted"
          color="primary"
          class="p-1 btn-expand"
          icon="vmdi-arrow-expand-all"
          @click.native="
            imageExpand(
              {
                src: option.image,
                srcSet: option.image,
                thumbnailWidth: '90px',
                thumbnailHeight: '65px',
              },
              index
            )
          "
        />
      </div>
      <div class="">
        <div class="text-grey text-sm">
          {{ option.name }}
          <span v-if="option.is_third_party" class="text-xs"
            >(Third-party Product)</span
          >
        </div>
        <div v-if="option.product_type === 'variation'">
          <s-button
            v-for="(v, index) in option.variants"
            :key="index"
            color="primary"
            size="sm"
            theme="muted"
            class="mr-1 mt-1 bt-border"
            :class="{
              'button-border': value.some(
                (p) => p.id === option.id && v.id === p.variant_id
              ),
            }"
            @click.native="
              select({
                ...option,
                price: v.price,
                variant_id: v.id,
                variant_name: v.name,
                product_price: v.price,
              })
            "
            title="Click to add"
          >
            <div class="text-normal">
              {{ v.name }}
            </div>
            <div>
              {{ "£" + v.price.toFixed(2) }}
            </div>
          </s-button>
        </div>
        <div class="text-grey-darkest text-bold text-lg" v-else>
          {{ "£" + option.price.toFixed(2) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    value: [Object, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isActive(item) {
      return this.value?.some((p) => p.id === item.id);
    },
    select(option, e) {
      if (
        e?.target?.localName === "img" ||
        e?.target?.name === "expand-image" ||
        e?.target?.tagName === "svg"
      ) {
        return;
      }
      if (!this.multiple) {
        // multiple selection
        let singleValues;
        if (this.value?.some((p) => p.category_id === option.category_id)) {
          if (option.variant_id) {
            if (this.value?.some((p) => p.variant_id === option.variant_id)) {
              singleValues = this.value.filter((p) => p.id !== option.id);
            } else {
              singleValues = this.value.filter(
                (p) =>
                  p.variant_id !== option.variant_id &&
                  p.id !== option.id &&
                  p.category_id !== option.category_id
              );
              singleValues = [...(singleValues || []), option];
            }
          } else {
            if (this.value?.some((p) => p.id === option.id)) {
              singleValues = this.value.filter((p) => p.id !== option.id);
            } else {
              singleValues = this.value.filter(
                (p) => p.category_id !== option.category_id
              );
              singleValues = [...singleValues, option];
            }
          }
        } else {
          singleValues = [...this.value, option];
        }

        this.$emit("input", singleValues);
      } else {
        // single selection
        let values;
        if (option.variant_id) {
          if (this.value?.some((v) => v.category_id === option.category_id)) {
            if (
              this.value?.some(
                (v) =>
                  v.id === option.id && v?.variant_id === option?.variant_id
              )
            ) {
              values = this.value.filter(
                (v) => v.variant_id !== option.variant_id
              );
            } else if (
              this.value?.some(
                (v) => v.id === option.id && v.variant_id !== option.variant_id
              )
            ) {
              values = this.value.filter((v) => v.id !== option.id);
              values = [...(values || []), option];
            } else {
              values = [...this.value, option];
            }
          } else {
            values = [...this.value, option];
          }
        } else {
          if (this.value?.some((v) => v.id === option.id)) {
            values = this.value.filter((v) => v.id !== option.id);
          } else {
            values = [...this.value, option];
          }
        }

        this.$emit("input", values);
      }
    },
    imageExpand(item, index) {
      // this.$refs[`silentbox-${index}`]?.[0]?.openOverlay(item);
      if (item.src) {
        this.$refs.silentbox[index]?.openOverlay(item);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.button-border {
  border: 2px solid #465659 !important;
}
.bt-border {
  border: 2px solid #daeeec;
}
.card {
  border: 2px solid #daeeec;
  transition: 0.3s;
  border-radius: 10px;
  &:hover {
    border: 2px solid #465659;
    transition: 0.3s;
  }
}
.card-active {
  border: 2px solid #465659;
  transition: 0.3s;
}
.img-size {
  height: 75px;
  width: 75px;
}
.disable-card {
  pointer-events: none;
}
</style>
<style lang="scss">
.lightbox-container {
  position: relative;
  .silentbox-item {
    img {
      border-radius: 8px;
      cursor: zoom-in !important;
    }
  }
  .btn-expand {
    right: 3px;
    bottom: 2px;
    position: absolute;
    opacity: 0.8;
  }
}
</style>
