<template>
  <div class=" mt-7 text-center">
    <div
      class="text-md text-grey mb-4"
      v-html="business.pre_planning_thank_you"
    />
  </div>
</template>
<script>
export default {
  props: {
    business: Object,
  },
  mounted() {
    localStorage.clear();
  },
  computed: {
    businessSlug() {
      return (
        window.location.origin + "/app/#/" + (this.business?.url_slug || "")
      );
    },
  },
};
</script>
