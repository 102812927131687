<template>
  <div>
    <company-profile v-if="!companySlug" :business="business" />
    <div class="p-5 information-spacing">
      <div class="information-width">
        <div class="text-grey-dark text-bold mb-4" v-html="bannerTitle" />
        <div class="text-grey mb-3" v-html="bannerDesc" />

        <s-button
          @click.native="create"
          align="right"
          class="mt-3"
          color="primary"
          theme="primary"
          label="Organize a Funeral"
          :loader="loading"
          icon="vmdi-arrow-right"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { createId } from "@/api/new-request/funeral";

export default {
  props: {
    companySlug: String,
    business: Object,
  },
  components: {
    CompanyProfile: require("@/components/CompanyProfile").default,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    bannerTitle() {
      // return this.business?.banner_title;
      return this.business?.welcome_screen_title_text;
    },
    bannerDesc() {
      return this.business?.welcome_screen_text;
    },
  },
  methods: {
    async create() {
      this.loading = true;
      let data = {};
      if (this.business?.id) {
        data.business_id = this.business?.id;
      }
      await createId(data)
        .then((res) => {
          this.$router.push({
            name: "funeral-starting",
            params: { funeralId: res.uuid },
          });
        })
        .catch((err) => {
          console.log("ERRRRR ", err);
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.information-width {
  width: 730px;
}
</style>
