<template>
  <div>
    <div class="text-primary text-bold text-md  mb-3">
      Funeral Notice Publishing Options
    </div>
    <s-row class="mb-3 common-row">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Local Newspaper
        </div>
        <div v-if="notice && notice.local_newspaper">
          {{ notice && notice.local_newspaper }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          National Newspaper
        </div>
        <div v-if="notice && notice.national_newspaper">
          {{ notice && notice.national_newspaper }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          {{ funeralDetails.business.name }}’s Facebook Page
        </div>
        <div>
          {{ notice && notice.facebook_page ? "Yes" : "No" }}
        </div>
      </s-column>
    </s-row>
    <s-row class="pb-4 mb-3 common-row">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Family Flowers Only with Donation
        </div>
        <div>
          {{
            notice && notice.family_flowers_only_with_donation ? "Yes" : "No"
          }}
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Family Flowers or Donation
        </div>
        <div>
          {{ notice && notice.family_flowers_or_donation ? "Yes" : "No" }}
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Charity Name
        </div>
        <div v-if="notice && notice.charity_name">
          {{ notice && notice.charity_name }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
  </div>
</template>
<script>
export default {
  props: {
    funeralDetails: Object,
  },
  data() {
    return {
      label: {
        " family-flowers-only-with-donation":
          "Family Flowers Only with Donation",
        "family-flowers-or-donation": "Family Flowers or Donation",
      },
    };
  },
  computed: {
    notice() {
      return this.funeralDetails?.notice;
    },
  },
};
</script>
