export default {
  path: "/:companySlug/pre-planning",
  component: require("@public/views/pre-planning/Index").default,
  redirect: {
    name: "pre-planning-welcome",
  },
  props: true,
  children: [
    {
      path: "welcome",
      name: "pre-planning-welcome",
      component: require("@/views/pre-planning-add/LandingPage").default,
      props: true,
      meta: {
        label: "Welcome",
        breadcrumbs: ["pre-planning-welcome"],
      },
    },
    {
      path: "thank-you",
      name: "pre-planning-thank-you",
      component: require("@public/views/pre-planning/ThankYou").default,
      props: true,
    },
    {
      path: ":prePlanningId",
      name: "pre-planning-starting",
      redirect: { name: "pre-planning-introduction" },
      component: require("@/views/pre-planning-add/Index").default,
      props: true,
      children: [
        {
          path: "",
          component: require("@/views/pre-planning-add/Introduction").default,
          name: "pre-planning-introduction",
          props: true,
          meta: {
            label: "Introduction Details",
            breadcrumbs: ["pre-planning-introduction"],
          },
        },
        {
          path: "type-details",
          component: require("@/views/pre-planning-add/CremationDetails")
            .default,
          name: "per-planning-cremation",
          props: true,
          meta: {
            label: "Burial/ Cremation Details",
            breadcrumbs: ["per-planning-cremation"],
          },
        },
        // {
        //   path: "view-visitation-details",
        //   component: require("@/views/pre-planning-add/MyCeremonyWishes")
        //     .default,
        //   name: "view-visitation-details",
        //   props: true,
        //   meta: {
        //     label: "Viewing & Visitation Details",
        //     breadcrumbs: ["view-visitation-details"],
        //   },
        // },
        {
          path: "my-ceremony-wishes",
          component: require("@/views/pre-planning-add/MyCeremonyWishes")
            .default,
          name: "my-ceremony-wishes",
          props: true,
          meta: {
            label: "My Ceremony Wishes",
            breadcrumbs: ["my-ceremony-wishes"],
          },
        },
        // {
        //   path: "funeral-transport",
        //   component: require("@/views/pre-planning-add/MyWakeWishes").default,
        //   name: "funeral-transport",
        //   props: true,
        //   meta: {
        //     label: "Funeral Transport",
        //     breadcrumbs: ["funeral-transport"],
        //   },
        // },
        {
          path: "view-visitation-details",
          component: require("@/views/pre-planning-add/MyWakeWishes").default,
          name: "view-visitation-details",
          props: true,
          meta: {
            label: "My Viewing & Visitation (Wake) Wishes",
            breadcrumbs: ["view-visitation-details"],
          },
        },
        // {
        //   path: "special-requests",
        //   component: require("@/views/pre-planning-add/MyPersonalWishes")
        //     .default,
        //   name: "special-requests",
        //   props: true,
        //   meta: {
        //     label: "Special Requests",
        //     breadcrumbs: ["special-requests"],
        //   },
        // },
        {
          path: "my-personal-wishes",
          component: require("@/views/pre-planning-add/MyPersonalWishes")
            .default,
          name: "my-personal-wishes",
          props: true,
          meta: {
            label: "My Personal Wishes",
            breadcrumbs: ["my-personal-wishes"],
          },
        },
        {
          path: "confirmation",
          component: require("@/views/pre-planning-add/Confirmation").default,
          name: "confirmation",
          props: true,
          meta: {
            label: "Confirmation",
            breadcrumbs: ["dashboard", "pre-planning-list", "confirmation"],
          },
        },
      ],
    },
  ],
};
