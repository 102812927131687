var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('s-layout',{staticClass:"type-box",attrs:{"full-height":"","right-size":"400px","right-classlist":['p-3', 'bl-lightest']},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"px-4 pt-3 template-content-width bb-lightest"},[(_vm.funeralDetails.introduction_image)?_c('div',{staticClass:"rectangle-ratio mb-3"},[_c('img',{attrs:{"src":_vm.funeralDetails.introduction_image}})]):_vm._e(),(_vm.funeralDetails.introduction_text)?_c('div',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(_vm.funeralDetails.introduction_text)}}):_vm._e()]),_c('item-form',{ref:"item",staticClass:"p-4",attrs:{"item":_vm.funeralId,"fields":_vm.fields,"save":_vm.save,"get":_vm.get,"footer":true,"breadcrumb":false,"redirect":false,"width":"400px","notify":true,"form-state":_vm.formValues},on:{"update:formState":function($event){_vm.formValues=$event},"update:form-state":function($event){_vm.formValues=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('s-field-validate',{attrs:{"label":"Funeral Type","rules":"required"}},[(!_vm.isTailored)?_c('s-radio',{attrs:{"options":_vm.typeOptions},nativeOn:{"change":function($event){_vm.titleValue = $event.target.value}},model:{value:(form.type),callback:function ($$v) {_vm.$set(form, "type", $$v)},expression:"form.type"}}):_c('s-radio',{attrs:{"options":_vm.typeOptions},nativeOn:{"change":function($event){_vm.serviceSelection = $event.target.value}},model:{value:(form.type),callback:function ($$v) {_vm.$set(form, "type", $$v)},expression:"form.type"}})],1),(_vm.isTailored)?_c('div',{staticClass:"text-primary text-bold text-md"},[_vm._v(" Funeral Ceremony Options ")]):_vm._e(),(_vm.isTailored)?_c('s-field-validate',[_c('div',[_c('div',{staticClass:"flex flex--fit flex--nowrap"},[_c('s-checkbox',{staticClass:"choice",attrs:{"id":"checkbox-1","label":"Funeral Service","name":"agree"},nativeOn:{"change":function($event){_vm.isServiceChecked = $event.target.checked}},model:{value:(form.is_funeral_service),callback:function ($$v) {_vm.$set(form, "is_funeral_service", $$v)},expression:"form.is_funeral_service"}}),_c('div',{staticClass:"text-grey-light text-sm ml-2"},[_vm._v(" (i.e Church, Chapel or Crematorium Chapel) ")])],1),(_vm.isServiceChecked)?_c('s-textbox',{staticClass:"mt-2",attrs:{"type":"text","spellcheck":"true"},model:{value:(form.funeral_service),callback:function ($$v) {_vm.$set(form, "funeral_service", $$v)},expression:"form.funeral_service"}}):_vm._e()],1)]):_vm._e(),_c('s-field-validate',{attrs:{"label":form.type == 'burial'
              ? 'Preferred Burial Location'
              : 'Preferred Cremation Location',"rules":"required"}},[_c('s-textbox',{attrs:{"type":"text","spellcheck":"true"},model:{value:(form.ceremony_location),callback:function ($$v) {_vm.$set(form, "ceremony_location", $$v)},expression:"form.ceremony_location"}})],1),(_vm.isTailored)?_c('s-field-validate',{attrs:{"label":"Preferred Date","optional":""}},[_c('v-date-picker',{attrs:{"max-allow":false,"min-allow":true,"clickable":true},model:{value:(form.ceremony_date),callback:function ($$v) {_vm.$set(form, "ceremony_date", $$v)},expression:"form.ceremony_date"}})],1):_vm._e(),(_vm.isTailored)?_c('s-field-validate',{staticClass:"v-time-picker",attrs:{"optional":"","label":"Preferred Time"}},[_c('v-date-picker',{attrs:{"max-allow":false,"min-allow":true,"mode":"time","clickable":true},model:{value:(form.ceremony_time),callback:function ($$v) {_vm.$set(form, "ceremony_time", $$v)},expression:"form.ceremony_time"}})],1):_vm._e(),(_vm.isTailored)?_c('s-field-validate',{attrs:{"label":"Preferred Funeral Route / Travel Arrangement (From Home etc.)"}},[_c('s-select',{attrs:{"placeholder":"Select","request":_vm.routeOptions},model:{value:(form.ceremony_route),callback:function ($$v) {_vm.$set(form, "ceremony_route", $$v)},expression:"form.ceremony_route"}})],1):_vm._e(),(_vm.isTailored)?_c('s-field',{attrs:{"label":"Funeral Webcast"}},[_c('s-radio',{attrs:{"options":_vm.webcastOptions},model:{value:(form.webcast),callback:function ($$v) {_vm.$set(form, "webcast", $$v)},expression:"form.webcast"}})],1):_vm._e()]}},{key:"footer",fn:function(ref){
              var saveItem = ref.saveItem;
              var saving = ref.saving;
return [_c('s-row',{staticClass:"g-2"},[_c('s-column',{attrs:{"size":3}},[_c('s-button',{staticClass:"bg-grey-lighter text-grey",attrs:{"fluid":"","theme":"muted","label":"Back"},nativeOn:{"click":function($event){return _vm.back($event)}}})],1),_c('s-column',{attrs:{"size":9}},[_c('s-button',{attrs:{"fluid":"","icon":"vmdi-arrow-right","align":"right","color":"primary","label":"Select Products","loader":saving},nativeOn:{"click":function($event){return saveItem($event)}}})],1)],1)]}}])})]},proxy:true},(_vm.isTailored)?{key:"right",fn:function(){return [_c('div',{staticClass:"text-primary text-bold text-md mb-3"},[_vm._v(" Your Summary & Guide Price ")]),_c('funeral-director',{attrs:{"business":_vm.funeralDetails.business}}),(_vm.serviceSelection)?_c('service-card',{attrs:{"type":_vm.serviceSelection,"business-id":_vm.funeralDetails.business_id}}):_vm._e()]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }