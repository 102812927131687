<template>
  <modal
    size="xs"
    id="otp-verification-modal"
    title="Verification Code"
    :closeOnEsc="false"
    :closeOnOverlay="false"
    :close="false"
  >
    <item-form
      :item="true"
      :fields="fields"
      :get="get"
      :save="save"
      class="p-4"
      :footer="true"
      :breadcrumb="false"
      :redirect="false"
      ref="item"
      :notify="true"
      :gap="3"
    >
      <template #default="{form}">
        <s-field-validate name="Verification Code" rules="required">
          <s-textbox v-model="form.code" type="number" />
        </s-field-validate>
      </template>
      <template #footer="{saveItem,saving}" :class="['']">
        <s-row class="">
          <s-column :size="12">
            <s-button
              fluid
              icon="vmdi-arrow-right"
              align="right"
              color="primary"
              label="Verify Code"
              @click.native="saveItem"
              :loader="saving"
            />
          </s-column>
        </s-row>
      </template>
    </item-form>
  </modal>
</template>
<script>
import { otpVerification } from "@/api/new-request/funeral";
export default {
  props: {
    funeralId: [Number, String],
  },
  components: {
    Modal: require("@/components/Modal").default,
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      loading: false,
      fields: ["code"],
    };
  },
  methods: {
    get() {
      return {};
    },
    save(id, data) {
      data.id = this.funeralId;
      return otpVerification(data).then((res) => {
        localStorage.setItem("vfa-code", data.code);
        this.$root.$emit("shilp-modal-close", "otp-verification-modal");
        this.$emit("refresh");
      });
    },
  },
};
</script>
