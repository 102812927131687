<template>
  <item-form
    :item="true"
    :fields="fields"
    class="p-4"
    :save="save"
    :get="get"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    ref="item"
    :notify="true"
  >
    <template #default="{form}">
      <div>
        <div
          v-if="typeOne && funeralDetails.introduction_image"
          class="rectangle-ratio mb-3"
        >
          <img :src="funeralDetails.introduction_image" />
        </div>
        <div
          v-if="typeOne && funeralDetails.introduction_text"
          v-html="funeralDetails.introduction_text"
          class="mb-4 pb-2"
        />
        <div class="text-grey-light text-lg pb-3 mb-3 bb-lightest">
          Summary
        </div>
        <your-information :funeral-details="funeralDetails" />
        <essential-details :funeral-details="funeralDetails" />
        <arrange-funeral
          v-if="typeTwo || typeThree"
          :type-three="typeThree"
          :funeral-details="funeralDetails"
        />

        <cost-estimate v-if="typeThree" :funeral-details="funeralDetails" />
        <!-- <product-choice v-if="typeThree" :funeral-details="funeralDetails" /> -->
        <!-- <urns v-if="typeTwo || typeThree" :funeral-details="funeralDetails" /> -->

        <urns v-if="typeTwo" :funeral-details="funeralDetails" />

        <press-notice
          v-if="(typeTwo || typeThree) && funeralDetails.is_notice_required"
          :funeral-details="funeralDetails"
        />

        <s-row
          class="mb-4 pb-3 details-box"
          v-if="(typeTwo || typeThree) && funeralDetails.is_notice_required"
        >
          <s-column :size="12" class="text-sm text-bold text-grey mb-2">
            Details
          </s-column>    
          <s-column
            :size="6"
            class="text-grey-darkest lh-2"
            style="white-space: pre-line;"
          >
            <div v-html="funeralDetails.notice_preview" />
          </s-column>
        </s-row>

        <!-- <deceased-details
          v-if="(typeTwo || typeThree) && funeralDetails.is_notice_required"
          :funeral-details="funeralDetails"
        />
        <family-references
          v-if="(typeTwo || typeThree) && funeralDetails.is_notice_required"
          :funeral-details="funeralDetails"
        /> -->
        <!-- <photograph
          v-if="typeTwo || typeThree"
          :funeral-details="funeralDetails"
        /> -->
        <notice-publish
          v-if="(typeTwo || typeThree) && funeralDetails.is_notice_required"
          :funeral-details="funeralDetails"
        />
        <!-- <disclaimer :funeral-details="funeralDetails" /> -->

        <div
          v-html="funeralDetails.confirmation_text"
          class=" bt-lightest pt-4"
        />

        <s-field-validate rules="required" name="status" class="mt-4">
          <s-checkbox
            v-model="form.status"
            id="checkbox-1"
            label="
        You tick this box to confirm that you have read and understand the details above.
        "
            class="choice text-bold mt-1"
            name="status"
            @change.native="checkDetails($event)"
          />
        </s-field-validate>
      </div>
    </template>

    <template #footer="{saveItem,saving}" :class="['']">
      <div>
        <s-row class="mb-2">
          <s-column :size="3">
            <s-button
              icon="vmdi-arrow-right"
              align="right"
              color="primary"
              label="Submit Your Details"
              @click.native="saveItem"
              :loader="saving"
              :disabled="isCheck"
            />
          </s-column>
        </s-row>
        <div class="text-xs text-grey-light mb-3">
          Submit the details for a call back from Funeral Director.
        </div>
      </div>
    </template>
  </item-form>
</template>
<script>
import { confirmDetails } from "@/api/new-request/funeral";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
    companySlug: String,
    business: Object,
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    YourInformation: require("./YourInformation").default,
    NoticePublish: require("./NoticePublish.vue").default,
    EssentialDetails: require("./EssentialDetails").default,
    ArrangeFuneral: require("./ArrangeFuneral").default,
    Urns: require("./Urns.vue").default,
    PressNotice: require("./PressNotice").default,
    CostEstimate: require("./CostEstimate").default,
    // ProductChoice: require("./ProductChoice").default,
    // Disclaimer: require("./Disclaimer").default,
    // FamilyReferences: require("./FamilyReferences").default,
    // DeceasedDetails: require("./Deceased").default,
    // Photograph: require("./Photograph").default,
  },
  data() {
    return {
      fields: ["status"],
      loader: false,
      isCheck: true,
    };
  },
  computed: {
    funeralType() {
      return this.funeralDetails?.funeral_type_id;
    },
    typeTwo() {
      return this.funeralType == 2;
    },
    typeThree() {
      return this.funeralType == 3;
    },
    typeOne() {
      return this.funeralType == 1;
    },
  },
  methods: {
    checkDetails($event) {
      if ($event.target.checked == true) {
        this.isCheck = false;
      } else {
        this.isCheck = true;
      }
    },
    get() {
      let status = "";
      if (this.funeralDetails?.status == "Open") {
        status = true;
        this.isCheck = false;
      }
      return {
        status,
      };
    },
    async save(id, data) {
      data.id = this.funeralId;
      if (data.status) {
        data.status = "Open";
      }

      this.loader = true;
      let code = localStorage.getItem("vfa-code");
      return confirmDetails(data, code).then((res) => {
        this.loader = false;
        this.$emit("refresh");
        if (this.companySlug) {
          this.$router.push({ name: "thank-you" });
        } else {
          this.$router.push({ name: "funeral-list" });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.intro-image {
  img {
    border-radius: 5px;
  }
}
.rectangle-ratio {
  width: 500px;
  height: 281px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
  }
}
@media (max-width:992px){
  .details-box{
    display: block !important;
  }   
}
</style>
