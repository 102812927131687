<template>
  <div class="category-container">
    <item-form
      :item="funeralId"
      :fields="fields"
      class="p-4"
      :save="save"
      :get="get"
      :form-state.sync="formValues"
      :footer="true"
      :breadcrumb="false"
      :redirect="false"
      width="450px"
      ref="item"
      :notify="true"
      :gap="2"
    >
      <template #default="{form}">
        <div class="text-primary text-bold text-md" v-if="funeralType">
          Select Urn
        </div>
        <sp-list
          v-if="funeralType"
          :actions="['']"
          endpoint="/v1/product"
          :filters="filters"
          :footer="false"
          :params="params"
        >
          <template #default="{items}">
            <s-field name="Urn">
              <div>
                <urns-options
                  v-for="(item, index) in items"
                  :key="index"
                  :item="item"
                  v-model="form.products"
                />
              </div>
            </s-field>
          </template>
          <template #empty>
            <div class="text-center mt-4 text-grey">No Product available.</div>
          </template>
        </sp-list>
        <div class="text-primary text-bold text-md">
          Funeral Notice
        </div>
        <s-field-validate
          label="Would you like to create funeral notice?"
          rules="required"
        >
          <s-radio
            :options="noticeOptions"
            v-model="form.is_notice_required"
            @change.native="noticeConfirm"
          />
        </s-field-validate>
        <div v-if="form.is_notice_required === 1" class="mt-2 disable">
          <urns-options :item="noticeValue" v-model="noticeValue" />
        </div>
      </template>
      <template #footer="{saveItem,saving}" :class="['']">
        <s-row class="row-block g-2">
          <s-column :size="3">
            <s-button
              fluid
              class="bg-grey-lighter mr-3 text-grey"
              theme="muted"
              label="Back"
              @click.native="back"
            />
          </s-column>
          <s-column :size="9">
            <s-button
              fluid
              icon="vmdi-arrow-right"
              align="right"
              color="primary"
              :label="label"
              @click.native="saveItem"
              :loader="saving || loading"
            />
          </s-column>
        </s-row>  
      </template>
    </item-form>
  </div>
</template>
<script>
import { productSave, getFuneralNotice } from "@/api/new-request/tailored";
import checkFuneral from "@/views/new-request/common/checkFuneral";
export default {
  mixins: [checkFuneral],
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    UrnsOptions: require("./Options").default,
  },
  data() {
    return {
      fields: [
        { name: "products", value: {} },
        { name: "is_notice_required", value: 0 },
      ],
      noticeValue: null,
      formValues: null,
      loading: false,
    };
  },
  mounted() {
    this.routeAllowed();
  },
  computed: {
    funeralType() {
      if (this.funeralDetails.type === "burial") {
        return false;
      }
      return true;
    },
    filters() {
      return {
        "category.name": "urn",
        type: "product",
      };
    },
    params() {
      return {
        business_id: this.funeralDetails?.business?.id,
      };
    },
    noticeOptions() {
      return [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ];
    },
    label() {
      if (this.formValues?.is_notice_required) {
        return "Funeral Notice Details";
      }
      return "Confirm Funeral Details";
    },
  },
  methods: {
    get() {
      let urnProduct = this.funeralDetails?.products.filter(
        (p) => p.category?.name === "Urn"
      );
      // this.noticeValue = this.funeralDetails?.products.find(
      //   (p) => p?.category?.name === "funeral notice"
      // );
      if (this.funeralDetails?.is_notice_required) {
        this.noticeConfirm(this.funeralDetails?.is_notice_required);
      }
      return {
        products: urnProduct?.[0] || {},
        is_notice_required: this.funeralDetails.is_notice_required,
      };
    },
    async save(id, data) {
      let finalData = {
        id: this.funeralId,
        is_notice_required: data.is_notice_required,
        products: data.products?.id ? [{ ...data.products }] : [],
      };
      let isNoticeSaved = this.funeralDetails?.products.some(
        (p) => p.category?.id === this.noticeValue?.[0]?.id
      );
      if (data.is_notice_required && !isNoticeSaved) {
        finalData.products = [...finalData.products, { ...this.noticeValue }];
      } else {
        finalData.products = finalData.products.filter(
          (p) => p.id !== this.noticeDetails?.[0]?.id
        );
      }

      let code = localStorage.getItem("vfa-code");
      return productSave(finalData, code).then((res) => {
        this.$emit("refresh");
        if (data.is_notice_required) {
          this.$router.push({ name: "deceased-details" });
        } else {
          this.$router.push({ name: "confirm-details" });
        }
        return res;
      });
    },
    back() {
      this.$router.push({ name: "funeral-type" });
    },
    async noticeConfirm(isNotice) {
      this.loading = true;
      let data = {
        business_id: this.funeralDetails.business_id,
      };
      if (this.formValues?.is_notice_required === 1 || isNotice) {
        await getFuneralNotice(data)
          .then((res) => {
            this.noticeValue = res.data?.[0];
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 2px solid #daeeec;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 2px solid #465659;
    transition: 0.3s;
  }
}
.card-active {
  border: 2px solid #465659;
  transition: 0.3s;
}
.disable {
  pointer-events: none;
}
</style>
