import Vue from "vue";
import App from "./App.vue";
import routes from "./router";
// import store from "../src/store";
import "../src/plugins";
import "../src/global";

Vue.config.productionTip = false;

new Vue({
    // store,
    router: routes,
    render: h => h(App)
}).$mount("#app");
