<template>
  <s-layout
    class="type-box"
    full-height
    right-size="400px"
    :right-classlist="['p-3', 'bl-lightest']"
  >
    <template #default>
      <div class="px-4 pt-3 template-content-width bb-lightest">
        <div
          v-if="funeralDetails.introduction_image"
          class="rectangle-ratio mb-3"
        >
          <img :src="funeralDetails.introduction_image" />
        </div>
        <div
          v-if="funeralDetails.introduction_text"
          v-html="funeralDetails.introduction_text"
          class="mb-2"
        />
      </div>
      <item-form
        :item="funeralId"
        :fields="fields"
        class="p-4"
        :save="save"
        :get="get"
        :footer="true"
        :breadcrumb="false"
        :redirect="false"
        width="400px"
        ref="item"
        :notify="true"
        :form-state.sync="formValues"
      >
        <template #default="{form}">
          <s-field-validate label="Funeral Type" rules="required">
            <s-radio
              :options="typeOptions"
              v-model="form.type"
              v-if="!isTailored"
              @change.native="titleValue = $event.target.value"
            />
            <s-radio
              v-else
              :options="typeOptions"
              v-model="form.type"
              @change.native="serviceSelection = $event.target.value"
            />
          </s-field-validate>
          <div class="text-primary text-bold text-md" v-if="isTailored">
            Funeral Ceremony Options
          </div>
          <s-field-validate v-if="isTailored">
            <div>
              <div class="flex flex--fit flex--nowrap">
                <s-checkbox
                  v-model="form.is_funeral_service"
                  id="checkbox-1"
                  label="Funeral Service"
                  class="choice"
                  name="agree"
                  @change.native="isServiceChecked = $event.target.checked"
                />
                <div class="text-grey-light text-sm ml-2">
                  (i.e Church, Chapel or Crematorium Chapel)
                </div>
              </div>
              <s-textbox
                v-if="isServiceChecked"
                class="mt-2"
                v-model="form.funeral_service"
                type="text"
                spellcheck="true"
              />
            </div>
          </s-field-validate>
          <s-field-validate
            :label="
              form.type == 'burial'
                ? 'Preferred Burial Location'
                : 'Preferred Cremation Location'
            "
            rules="required"
          >
            <s-textbox
              v-model="form.ceremony_location"
              type="text"
              spellcheck="true"
            />
          </s-field-validate>

          <s-field-validate label="Preferred Date" optional v-if="isTailored">
            <v-date-picker
              v-model="form.ceremony_date"
              :max-allow="false"
              :min-allow="true"
              :clickable="true"
            />
            <!-- mode="dateTime" -->
          </s-field-validate>

          <s-field-validate
            optional
            v-if="isTailored"
            label="Preferred Time"
            class="v-time-picker"
          >
            <v-date-picker
              :max-allow="false"
              :min-allow="true"
              mode="time"
              v-model="form.ceremony_time"
              :clickable="true"
            />
          </s-field-validate>

          <s-field-validate
            label="Preferred Funeral Route / Travel Arrangement (From Home etc.)"
            v-if="isTailored"
          >
            <s-select
              placeholder="Select"
              v-model="form.ceremony_route"
              :request="routeOptions"
            />
          </s-field-validate>
          <s-field label="Funeral Webcast" v-if="isTailored">
            <s-radio :options="webcastOptions" v-model="form.webcast" />
          </s-field>
        </template>
        <template #footer="{saveItem,saving}" :class="['']">
          <s-row class="g-2">
            <s-column :size="3">
              <s-button
                fluid
                class="bg-grey-lighter text-grey"
                theme="muted"
                label="Back"
                @click.native="back"
              />
            </s-column>
            <s-column :size="9">
              <s-button
                fluid
                icon="vmdi-arrow-right"
                align="right"
                color="primary"
                label="Select Products"
                @click.native="saveItem"
                :loader="saving"
              />
            </s-column>
          </s-row>
        </template>
      </item-form>
    </template>
    <template #right v-if="isTailored">
      <div class="text-primary text-bold text-md mb-3">
        Your Summary & Guide Price
      </div>
      <funeral-director :business="funeralDetails.business" />
      <service-card
        v-if="serviceSelection"
        :type="serviceSelection"
        :business-id="funeralDetails.business_id"
      />
    </template>
  </s-layout>
</template>
<script>
import { saveFuneralOptions } from "@/api/new-request/funeral";
import { routeOptions } from "@/api/new-request/tailored";
import format from "date-fns/format";
import checkFuneral from "@/views/new-request/common/checkFuneral";

export default {
  mixins: [checkFuneral],
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    VDatePicker: require("@/components/VDatePicker").default,
    ServiceCard: require("../common/ServiceSelection").default,
    FuneralDirector: require("../common/FuneralDirector").default,
  },
  computed: {
    isTailored() {
      if (this.funeralDetails?.funeral_type_id === 3) {
        return true;
      }
      return false;
    },
    typeOptions() {
      return [
        { label: "Cremation", value: "cremation" },
        { label: "Burial", value: "burial" },
      ];
    },
    webcastOptions() {
      return [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ];
    },
  },
  data() {
    return {
      fields: [
        { name: "type", value: "cremation" },
        "is_funeral_service",
        "ceremony_location",
        "ceremony_date",
        "ceremony_route",
        "webcast",
        "funeral_service",
        "ceremony_time",
      ],
      serviceSelection: null,
      isServiceChecked: null,
      formValues: null,
      titleValue: null,
      routeOptions,
    };
  },
  mounted() {
    this.routeAllowed();
    this.serviceSelection = this.funeralDetails.type;
  },
  methods: {
    get() {
      var currentType = null;
      if (this.funeralDetails.type == null) {
        currentType = "cremation";
        this.funeralDetails.type = currentType;
      } else {
        currentType = this.funeralDetails.type;
      }
      this.titleValue = currentType;
      this.isServiceChecked = Boolean(this.funeralDetails?.is_funeral_service);
      let dummyDate;
      if (this.funeralDetails?.ceremony_time) {
        let date = format(new Date(), "yyyy-MM-dd");
        dummyDate = `${date} ${this.funeralDetails.ceremony_time}`;
        // dummyDate = Date.parse(`${date} ${this.funeralDetails.ceremony_time}`);
      }
      return {
        ...this.funeralDetails,
        type: currentType,
        ceremony_time: dummyDate,
      };
    },
    async save(id, data) {
      data.id = this.funeralId;
      if (data?.ceremony_time) {
        data.ceremony_time = format(new Date(data.ceremony_time), "HH:mm:ss");
      }
      let code = localStorage.getItem("vfa-code");
      return saveFuneralOptions(data, code).then((res) => {
        this.$emit("refresh");
        if (this.funeralDetails?.funeral_type_id === 3) {
          this.$router.push({ name: "funeral-product" });
        } else {
          this.$router.push({
            name: "urns",
          });
        }
        return res;
      });
    },
    back() {
      this.$router.push({ name: "funeral-options" });
    },
  },
};
</script>

<style lang="scss">
.v-time-picker {
  .vc-date-time {
    .vc-date {
      display: none !important;
    }
  }
}
.template-content-width {
  width: 700px;
}
@media (max-width:992px){
  .type-box{
    .template-content-width{
      width:100%;
    }
    .item-form{
      width:100% !important;
    }
  }
}
</style>
