import Vue from "vue";
import VueRouter from "vue-router";
import prePlanning from "./prePlanning";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    component: require("@public/views/Redirect").default,
  },
  {
    path: "/:companySlug",
    component: require("@public/views/Company").default,
    props: true,
    children: [
      {
        path: "",
        name: "funeral-first",
        component: require("@/views/new-request/LandingPage").default,
        props: true
      },
      {
        path: "thank-you",
        component: require("@public/views/ThankYou").default,
        name: "thank-you",
        props: true
      },
      {
        path: ":funeralId",
        component: require("../../src/views/new-request/Index").default,
        name: "funeral-starting",
        redirect: { name: "contact-details" },
        props: true,
        children: [
          {
            path: "",
            component: require("../../src/views/new-request/ContactDetails").default,
            name: "contact-details",
            props: true,
            meta: {
              label: "Contact Details",
              breadcrumbs: ["dashboard", "funeral-list", "contact-details"],
            },
          },
          {
            path: "funeral-options",
            component: require("../../src/views/new-request/funeral-option/Index").default,
            name: "funeral-options",
            props: true,
            meta: {
              label: "Funeral Options",
              breadcrumbs: ["dashboard", "funeral-list", "funeral-options"],
            },
          },
          {
            path: "funeral-type",
            component: require("../../src/views/new-request/funeral-type/Index").default,
            name: "funeral-type",
            props: true,
            meta: {
              label: "Funeral Type",
              breadcrumbs: ["dashboard", "funeral-list", "funeral-type"],
            },
          },
          {
            path: "funeral-product",
            component: require("../../src/views/new-request/funeral-product/Index").default,
            name: "funeral-product",
            props: true,
            meta: {
              label: "Funeral Product",
              breadcrumbs: ["dashboard", "funeral-list", "funeral-product"],
            },
          },
          {
            path: "confirm-funeral-details",
            component: require("../../src/views/new-request/confirm-details/Index").default,
            name: "confirm-details",
            props: true,
            meta: {
              label: "Confirm Funeral Details",
              breadcrumbs: ["dashboard", "funeral-list", "confirm-details"],
            },
          },
          {
            path: "urns",
            component: require("../../src/views/new-request/urns/Index").default,
            name: "urns",
            props: true,
            meta: {
              label: "urns",
              breadcrumbs: ["dashboard", "funeral-list", "urns"],
            },
          },
          {
            path: "funeral-notice-details",
            component: require("../../src/views/new-request/notice-details/Index").default,
            name: "funeral-notice-details",
            props: true,
            meta: {
              label: "Funeral Notice Info",
              breadcrumbs: ["dashboard", "funeral-list", "funeral-notice-details"],
            },
            children: [
              {
                path: "",
                component: require("../../src/views/new-request/notice-details/DeceasedDetails").default,
                name: "deceased-details",
                props: true,
                meta: {
                  label: "Details of the Deceased",
                  breadcrumbs: ["dashboard", "funeral-list", "funeral-notice-details", "deceased-details"],
                },
              },
              {
                path: "family-references",
                component: require("../../src/views/new-request/notice-details/FamilyReference").default,
                name: "family-references",
                props: true,
                meta: {
                  label: "Funeral Notice Family References",
                  breadcrumbs: ["dashboard", "funeral-list", "funeral-notice-details", "family-references"],
                },
              },
              {
                path: "photograph",
                component: require("../../src/views/new-request/notice-details/Photograph").default,
                name: "photograph",
                props: true,
                meta: {
                  label: "Photograph",
                  breadcrumbs: ["dashboard", "funeral-list", "funeral-notice-details", "photograph"],
                },
              },
              {
                path: "publish-options",
                component: require("../../src/views/new-request/notice-details/PublishOptions").default,
                name: "publish-options",
                props: true,
                meta: {
                  label: "Funeral Notice Publishing Options",
                  breadcrumbs: ["dashboard", "funeral-list", "funeral-notice-details", "publish-options"],
                },
              },
              {
                path: "preview",
                component: require("../../src/views/new-request/notice-details/Preview").default,
                name: "preview",
                props: true,
                meta: {
                  label: "Funeral Notice Preview",
                  breadcrumbs: ["dashboard", "funeral-list", "funeral-notice-details", "preview"],
                },
              },
            ]
          }
        ]
      }
    ]
  },
  prePlanning
];

const router = new VueRouter({
  routes,
  mode: "hash"
});

export default router;
