var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-container"},[_c('item-form',{ref:"item",staticClass:"p-4",attrs:{"item":_vm.funeralId,"fields":_vm.fields,"save":_vm.save,"get":_vm.get,"form-state":_vm.formValues,"footer":true,"breadcrumb":false,"redirect":false,"width":"450px","notify":true,"gap":2},on:{"update:formState":function($event){_vm.formValues=$event},"update:form-state":function($event){_vm.formValues=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [(_vm.funeralType)?_c('div',{staticClass:"text-primary text-bold text-md"},[_vm._v(" Select Urn ")]):_vm._e(),(_vm.funeralType)?_c('sp-list',{attrs:{"actions":[''],"endpoint":"/v1/product","filters":_vm.filters,"footer":false,"params":_vm.params},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('s-field',{attrs:{"name":"Urn"}},[_c('div',_vm._l((items),function(item,index){return _c('urns-options',{key:index,attrs:{"item":item},model:{value:(form.products),callback:function ($$v) {_vm.$set(form, "products", $$v)},expression:"form.products"}})}),1)])]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center mt-4 text-grey"},[_vm._v("No Product available.")])]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"text-primary text-bold text-md"},[_vm._v(" Funeral Notice ")]),_c('s-field-validate',{attrs:{"label":"Would you like to create funeral notice?","rules":"required"}},[_c('s-radio',{attrs:{"options":_vm.noticeOptions},nativeOn:{"change":function($event){return _vm.noticeConfirm($event)}},model:{value:(form.is_notice_required),callback:function ($$v) {_vm.$set(form, "is_notice_required", $$v)},expression:"form.is_notice_required"}})],1),(form.is_notice_required === 1)?_c('div',{staticClass:"mt-2 disable"},[_c('urns-options',{attrs:{"item":_vm.noticeValue},model:{value:(_vm.noticeValue),callback:function ($$v) {_vm.noticeValue=$$v},expression:"noticeValue"}})],1):_vm._e()]}},{key:"footer",fn:function(ref){
var saveItem = ref.saveItem;
var saving = ref.saving;
return [_c('s-row',{staticClass:"row-block g-2"},[_c('s-column',{attrs:{"size":3}},[_c('s-button',{staticClass:"bg-grey-lighter mr-3 text-grey",attrs:{"fluid":"","theme":"muted","label":"Back"},nativeOn:{"click":function($event){return _vm.back($event)}}})],1),_c('s-column',{attrs:{"size":9}},[_c('s-button',{attrs:{"fluid":"","icon":"vmdi-arrow-right","align":"right","color":"primary","label":_vm.label,"loader":saving || _vm.loading},nativeOn:{"click":function($event){return saveItem($event)}}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }