<template>
  <div>
    <div class="text-primary text-bold text-md  mb-2">
      Funeral option chosen - {{ funeralName }} Funeral
    </div>
    <s-row class="common-row mb-4">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Funeral Type
        </div>
        <div class="text-capitalize">
          {{ funeralDetails.type }}
        </div>
      </s-column>
      <s-column :size="3" v-if="typeThree">
        <div class="text-sm text-grey mb-1">
          Service Location
        </div>
        <div v-if="funeralDetails.funeral_service">
          {{ funeralDetails.funeral_service }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Ceremony Location
        </div>
        <div v-if="funeralDetails.ceremony_location">
          {{ funeralDetails.ceremony_location }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
    <s-row class="common-row mb-4" v-if="typeThree">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Funeral Route
        </div>
        <div v-if="funeralDetails.ceremony_route">
          {{ funeralDetails.ceremony_route }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Date
        </div>
        <div v-if="funeralDetails.ceremony_date">
          {{ format(new Date(funeralDetails.ceremony_date), "dd/MM/yyyy") }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Time
        </div>
        <div v-if="funeralDetails.ceremony_time">{{ ceremonyTime }}</div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
    <s-row class="mb-4 pb-2" v-if="typeThree">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">
          Webcast
        </div>
        <div>{{ funeralDetails.webcast ? "Yes" : "No" }}</div>
      </s-column>
    </s-row>
  </div>
</template>
<script>
import format from "date-fns/format";
export default {
  props: {
    typeThree: Boolean,
    funeralDetails: Object,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    funeralName() {
      if (this.typeThree) {
        return "Tailored";
      }
      return "Simple";
    },
    ceremonyTime() {
      let formatTime = "";
      if (this.funeralDetails?.ceremony_time) {
        let dummyDate = format(new Date(), "yyyy-MM-dd");
        dummyDate = `${dummyDate} ${this.funeralDetails?.ceremony_time}`;
        formatTime = format(new Date(dummyDate), "hh:mm a");
        return formatTime;
      }
      return formatTime;
    },
  },
};
</script>
